
import React, { createContext, useReducer } from "react";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

export const ThemeContext = createContext();

const initialState = {
    darkMode: true,
};

const themeReducer = (state, action) => {
    switch (action.type) {
        case "LIGHTMODE":
            return { darkMode: false };
        case "DARKMODE":
            return { darkMode: true };
        default:
            return state;
    }
};


function createMuiTheme(darkMode) {
    return createTheme({
        palette: {
            mode: darkMode ? "dark" : "light",
        },
        typography: {
            fontFamily: "'Ysabeau', sans-serif",
        },
    });
}
export function ThemeProvider(props) {
    const [state, dispatch] = useReducer(themeReducer, initialState);
    const theme = createMuiTheme(state.darkMode);
    return (
        <ThemeContext.Provider value={{ state, dispatch }}>
            <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
}