import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import DarkModeSwitcher from "../darkmode-switcher";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../theme";
import UserAvatar from "../user-avatar";
import "./style.css";
import { useTranslation } from "react-i18next";
import esImg from "../../assets/language/es-lang.webp";
import enImg from "../../assets/language/en-lang.webp";
import { useAuth } from "../../contexts/AuthContext";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [traduction, setLanguage] = useTranslation("global");
    const switchLanguageES = () => {
        setLanguage.changeLanguage("es");
    };
    const switchLanguageEN = () => {
        setLanguage.changeLanguage("en");
    };
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    /**
     * MENU ELEMENTS
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const { isAuthenticated } = useAuth();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };
    const handleClose = () => {
        setIsOpen(!isOpen);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    // END OF MENU ELEMENTS
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="fixed"
                sx={
                    darkMode
                        ? {
                            borderRadius: "15px",
                            backgroundColor: "#000000",
                            top: "auto",
                            bottom: 0,
                        }
                        : {
                            backgroundColor: "#ffffff",
                            borderRadius: "150px",
                            border: "none",
                            top: "auto",
                            bottom: 0,
                        }
                }
            >
                <Toolbar
                    sx={
                        darkMode
                            ? { backgroundColor: "black", margin: "1px" }
                            : { backgroundColor: "#3E709C" }
                    }
                    className={
                        darkMode
                            ? "header__container"
                            : "header__container-light"
                    }
                >
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        aria-describedby={id}
                        onClick={handleClick}
                        sx={{
                            mr: 2,
                            "&:hover": { backgroundColor: "transparent" },
                        }}
                    >
                        {isOpen ? (
                            <CloseIcon className="icon-animation" />
                        ) : (
                            <MenuIcon className="icon-animation" />
                        )}
                    </IconButton>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        trans
                    >
                        <div
                            className={
                                darkMode
                                    ? "header__menu menu-dark header__container"
                                    : "header__menu menu-light header__container-light"
                            }
                        >
                            <Link
                                onClick={handleClose}
                                to={isAuthenticated ? "/profile" : "/login"}
                                className={
                                    darkMode ? "link-dark" : "link-light"
                                }
                            >
                                {isAuthenticated
                                    ? traduction("menu.option15")
                                    : traduction("menu.option1")}
                            </Link>
                            <Link
                                onClick={handleClose}
                                to="/jobs"
                                className={
                                    darkMode ? "link-dark" : "link-light"
                                }
                            >
                                {traduction("menu.option2")}
                            </Link>
                            <Link
                                onClick={handleClose}
                                to="/postjob"
                                className={
                                    darkMode ? "link-dark" : "link-light"
                                }
                            >
                                {traduction("menu.option3")}
                            </Link>
                            <div className="landing__lang-btns">
                                <img
                                    onClick={switchLanguageES}
                                    alt="es_language"
                                    src={esImg}
                                    height="25"
                                    width="25"
                                    className="landing__btn"
                                ></img>
                                <img
                                    onClick={switchLanguageEN}
                                    alt="en_language"
                                    src={enImg}
                                    height="25"
                                    width="25"
                                    className="landing__btn"
                                ></img>
                            </div>
                        </div>
                    </Popover>

                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        {/* News */}
                    </Typography>
                    <DarkModeSwitcher />
                    <Link
                        className="avatar-link"
                        to={isAuthenticated ? "/profile" : "/login"}
                    >
                        <UserAvatar
                            online={isAuthenticated ? "true" : undefined}
                        />
                    </Link>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Header;
