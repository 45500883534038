import React from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


const FilterTool = ({totalJobs, darkMode, traductedText }) => {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Chip label={
                <span>
                    <span style={darkMode ? { color: '#4ff701' } : { color: '#00008B' }}>{totalJobs}</span> {traductedText}
                </span>
            } variant="outlined"/>
        </Stack>
    );
}

export default FilterTool;
