import React from "react";
import { Navigate } from "react-router-dom";

// Source: https://www.robinwieruch.de/react-router-private-routes/ <- add role based private routes

function PrivateRoute({ isSignedIn, children }) {
    if (!isSignedIn) {
        return <Navigate to="/jobs" replace />;
    }
    return children;
}
export default PrivateRoute;
