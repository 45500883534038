import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from "react";
import useSession from "../components/custom-hooks/useSession";
import { setAuthToken, removeAuthToken, getAuthToken } from "../utils/auth/authService";
import serverConnect from "../axios";
// Create context
const AuthContext = createContext();

// Create context provider
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const updateAuthStatus = useCallback(async () => {
        const sessionValid = await useSession();
        setIsAuthenticated(sessionValid);
    }, []);

    const login = async (email, password) => {
        // Make the login request to the server with the user's credentials
        const body = {
            email,
            password,
        };
        try {
            const response = await serverConnect.post("/auth/login", body);
            // If the server returns tokens, they are valid. Store them and set isAuthenticated to true.
            if (
                response.data.tokens.access &&
                response.data.tokens.refresh &&
                response.status >= 200 &&
                response.status < 300
            ) {
                setAuthToken(response.data.tokens.access, "access_token");
                setAuthToken(response.data.tokens.refresh, "refresh_token");
                setIsAuthenticated(true); // This is setting the state inside AuthProvider
                return "success";
            }
        } catch (err) {
            if (err.response?.data.message === "Incorrect email or password")
                return "wrong pass";
            if (err.response?.data.message === "User email not verified")
                return "email not verified";
            return "error";
        }
        return "fail";
    };

    const logout = async () => {
        const refreshToken = getAuthToken("refresh_token");
        const body = { refreshToken };
        removeAuthToken('access_token');
        removeAuthToken('refresh_token');
        setIsAuthenticated(false);
        try {
            await serverConnect.post("/auth/logout", body);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        updateAuthStatus();
    }, [updateAuthStatus]);

    const value = {
        isAuthenticated,
        updateAuthStatus,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

// Custom hook to use the auth context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
