import axios from "axios";

axios.defaults.withCredentials = true;
// "https://teletrabajo-app.onrender.com/v1"
const serverConnect = axios.create({
    // baseURL: "http://localhost:8000/v1",
    baseURL: "https://teletrabajo-app.onrender.com/v1",
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
});

export default serverConnect;
