import JobPost from "../../components/job-post";
import React from "react";
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import "./style.css";
import { ThemeContext } from "../../theme";
import serverConnect from "../../axios";
import SearchBar from "../../components/search-bar";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/loading-spinner";
import FilterTool from "../../components/filter-tool";

const Home = () => {
    // Variables
    const [traduction, _] = useTranslation("global"); // eslint-disable-line no-unused-vars
    const theme = useContext(ThemeContext);
    const [jobsCards, setJobsCards] = useState([]);
    const darkMode = theme.state.darkMode;
    const [allJobs, setAllJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(0);
    const [totalJobs, setTotalJobs] = useState(0);
    const loaderRef = useRef(null);

    // Functions
    const fetchJobs = useCallback(async () => {
        saveScrollPosition();
        setIsLoading(true);
        let dataLength = 0;

        try {
            const response = await serverConnect.get(
                `/jobs/all?page=${page}&search=${searchValue}`
            );
            dataLength = response.data.results?.length || 0;
            setTotalJobs(response.data.total || 0);
            if (dataLength > 0) {
                setAllJobs((prevJobs) => [
                    ...prevJobs,
                    ...response.data.results,
                ]);
            } else {
                setAllJobs([]);
            }
        } finally {
            setIsLoading(false);
        }

        return dataLength;
    }, [page, searchValue]);

    const saveScrollPosition = () => {
        window.savedScrollPosition = window.scrollY;
    }

    const generateJobPosts = useCallback((jobs) => {
        const jobCards = jobs?.map((e, i) => <JobPost
            id={e.id}
            key={i}
            title={e.title}
            company={e.company}
            category={e.category}
            location={e.location}
            salaryMin={e.salary_min}
            salaryMax={e.salary_max}
            description={e.description}
            age={e.created}
            url={e.redirect_url}
        />     
        );
        setJobsCards(jobCards);
    }, []);

    const initObserver = () => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
        };
        // eslint-disable-next-line no-unused-vars
        const observerCallback = (entries, observer) => {         
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
        };

        const newObserver = new IntersectionObserver(observerCallback, options);
        if (loaderRef.current) {
            newObserver.observe(loaderRef.current);
        }

        return () => {
            newObserver.disconnect();
        };
    };

    const sortJobsBySearch = (search) => {
        setSearchValue(search);
        setAllJobs([]);
        setPage(0);
    };

    useEffect(() => {
        if (allJobs) {
            generateJobPosts(allJobs);
        }
    }, [allJobs, generateJobPosts]);

    useEffect(() => {
        if (page > 0) {
            fetchJobs();
        }
    }, [page, fetchJobs]);

    // Add this useEffect to call initObserver when the component is mounted
    useEffect(() => {
        const cleanup = initObserver();
        return cleanup;
    }, []);

    useEffect(() => {
        if (window.savedScrollPosition !== undefined) {
            window.scrollTo(0, window.savedScrollPosition);
            delete window.savedScrollPosition;
        }
    }, [jobsCards]);

    // Render
    return (
        <div
            className={
                darkMode
                    ? "home_main home_main-dark"
                    : "home_main home_main-light"
            }
        > 
            <div className="upper_side">
                <h1>{traduction("home.greetings")}</h1>
                <h3 style={{ paddingLeft:"2rem", paddingRight:"2rem"}}>{traduction("home.subtitle")}</h3>
                <br />
                <SearchBar searchCallBack={sortJobsBySearch}/>
                {!isLoading && <FilterTool
                    totalJobs={totalJobs}
                    darkMode={darkMode}
                    traductedText={traduction("home.offers")}
                />}
            </div>
            {/* <Stack direction="column" spacing="1"> */}
            
            <br />
            <div
                className={
                    darkMode
                        ? "job_posts_container home_main-dark"
                        : "job_posts_container home_main-light"
                }
            >
                {isLoading ? <Spinner style={{ marginTop: 50}}/> : jobsCards}
                <div ref={loaderRef} style={{ width: "100%", height: "5px" }} />
            </div>
        </div>
    );
};

export default Home;
