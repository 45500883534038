
import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import "./style.css";
import { styled } from "@mui/material/styles";
import { ThemeContext } from "../../theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { useAuth } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";

const Login = () => {
    const { jobID } = useParams();
    const navigate = useNavigate();
    const [wrongPass, setWrongPass] = useState(false);
    const [userEmailNotVerified, setEmailNotVerified] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [passFocus, setPassFocus] = useState(false);
    const [handlingLogin, setHandlingLogin] = useState(false);
    const { login } = useAuth();
    const [traduction, setLanguage] = useTranslation("global"); // eslint-disable-line no-unused-vars
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const CustomButton = styled(Button)({
        color: darkMode ? "black" : "white",
        backgroundColor: darkMode ? "white" : "black",
        "&:hover": {
            backgroundColor: darkMode ? "gray" : "gray",
            borderColor: "black",
        },
    });
    const CustomTextField = styled(TextField)(() => ({
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: darkMode ? "white" : "black",
            },
            "&:hover fieldset": {
                borderColor: darkMode ? "white" : "black",
            },
            "&.Mui-focused fieldset": {
                borderColor: darkMode ? "white" : "black",
            },
            "& .MuiOutlinedInput-input": {
                color: darkMode ? "white" : "black",
            },
        },
        "& .MuiInputLabel-root": {
            color: darkMode ? "white !important" : "black !important",
        },
        "& .MuiInputBase-root": {
            color: darkMode ? "white" : "black",
        },
    }));
    const handleRegisterRedirect = () => {
        navigate("/register");
    };

    if (wrongPass) {
        setTimeout(() => {
            setWrongPass(false);
        }, 3000);
    }
    if (userEmailNotVerified) {
        setTimeout(() => {
            setEmailNotVerified(false);
        }, 3000);
    }

    const handleInputFocus = (e) => {
        setWrongPass(false);
        if (e.target.name === "email" && wrongPass && !handlingLogin)
            setEmailFocus(true);
        if (e.target.name === "password" && wrongPass && !handlingLogin)
            setPassFocus(true);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setHandlingLogin(true);
        try {
            const loginAttemp = await login(e.target.email.value, e.target.password.value);
            if (loginAttemp === "wrong pass") setWrongPass(true);
            if (loginAttemp === "email not verified") setEmailNotVerified(true);
            if (loginAttemp === "success" &&  jobID !== undefined && jobID !== '') {
                navigate(`/job/${jobID}`);
            } else if (loginAttemp === "success") navigate("/home");
        } catch (err) {
            console.log('error trying to log in');
            navigate("/login");
        } finally {
            setHandlingLogin(false);
        }
    };

    return (
        <form onSubmit={handleLogin}>
            <Stack
                className={
                    darkMode
                        ? "login-main-layout"
                        : "login-main-layout dark-mode"
                }
            >
                <CustomTextField
                    onFocus={handleInputFocus}
                    name="email"
                    margin="dense"
                    className="login-text-field"
                    id="outlined-password-input-email"
                    label={traduction("login.id")}
                    type="email"
                    autoComplete="current-password"
                    variant="outlined"
                    autoFocus={emailFocus ? true : false}
                    required
                />
                <CustomTextField
                    onFocus={handleInputFocus}
                    name="password"
                    margin="dense"
                    className="login-text-field"
                    id="outlined-password-input-password"
                    label={traduction("login.pass")}
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    autoFocus={passFocus ? true : false}
                    required
                />
                {wrongPass && (
                    <FormHelperText margin="dense" error>
                        {traduction("login.wrongPass")}
                    </FormHelperText>
                )}
                {userEmailNotVerified && (
                    <FormHelperText margin="dense" error>
                        {traduction("login.emailNotVerified")}
                    </FormHelperText>
                )}
                <Stack spacing={2} direction="row" marginTop={2}>
                    <CustomButton
                        key="register-btn"
                        margin="dense"
                        variant="outlined"
                        onClick={handleRegisterRedirect}
                    >
                        {traduction("login.btn2")}
                    </CustomButton>
                    <CustomButton
                        className={
                            darkMode ? "login-btn_dark-mode" : "login-btn"
                        }
                        key="login-btn"
                        margin="dense"
                        variant="contained"
                        type="submit"
                    >
                        {traduction("login.btn1")}
                    </CustomButton>
                </Stack>
            </Stack>
        </form>
    );
};

export default Login;
