import React, { useState, useContext, useRef } from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Chip from '@mui/material/Chip';
import EuroIcon from '@mui/icons-material/Euro';
import CardContent from "@mui/material/CardContent";
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { CardActionArea } from "@mui/material";
import defaultJobImage from "../../assets/defaultJobImage.png";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeContext } from "../../theme";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import SocialShare from "../social-share";
import "./style.css";

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));
/**
 *
 * @param {*} props
 *      id: MongoDB document _id used to remember the original job post after redirection
 *      image: url to the image of the company, set a default in case it fails
 *      title: job title
 *      company: company name
 *      category: job type
 *      location: job location
 *      salaryMin: if specified in offer
 *      salaryMax: conditinal aswell
 *      description: job role description
 *      age: time gone since job posted
 *      url: url to job post
 * @return
 */
const JobPost = (props) => {
    const { isAuthenticated } = useAuth();
    let colorIndex = useRef(0);
    const navigate = useNavigate();
    // TODO -> Implement a better color picker - This is BUGGED always returning 0
    function generateSequence() {
        if (colorIndex.current != 0) colorIndex += 1;
        return colorIndex;
    }
    // -------------------------------------------------------------- BUGGED
    // eslint-disable-next-line no-unused-vars
    const [traduction, _] = useTranslation("global");
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const cardColorPicker = generateSequence();
    const cardColors = ["#647C90"]
    /* const cardColors = [
        "#000000",
        "#000005",
        "#00000a",
        "#00000f",
        "#000014",
        "#00001a",
        "#00001f",
        "#000024",
        "#000029",
        "#00002e",
        "#000034",
        "#000039",
        "#00003e",
        "#000043",
        "#000048",
        "#00004e",
        "#000053",
        "#000058",
        "#00005d",
        "#000062",
        "#000068",
        "#00006d",
        "#000072",
        "#000077",
        "#00007c",
        "#000082",
        "#000087",
        "#00008c",
        "#000091",
        "#000096",
        "#00009c",
        "#0000a1",
        "#0000a6",
        "#0000ab",
        "#0000b0",
        "#0000b6",
        "#0000bb",
        "#0000c0",
        "#0000c5",
        "#0000ca",
        "#0000d0",
        "#0000d5",
        "#0000da",
        "#0000df",
        "#0000e4",
        "#0000ea",
        "#0000ef",
        "#0000f4",
        "#0000f9",
        "#0000ff"
    ]; */

    const isSmallScreen = useMediaQuery("(max-width:600px)");
    const isMediumScreen = useMediaQuery(
        "(min-width:601px) and (max-width:900px)"
    );
    const isLargeScreen = useMediaQuery(
        "(min-width:901px) and (max-width:1200px)"
    );
    const isHugeScreen = useMediaQuery("(min-width:1536px)");

    const cardSize = isSmallScreen
        ? "90%"
        : isMediumScreen
            ? "80%"
            : isLargeScreen
                ? "70%"
                : isHugeScreen
                    ? "60%"
                    : "50%";
    const cardHeight = isSmallScreen
        ? "100px"
        : isMediumScreen
            ? "120px"
            : isLargeScreen
                ? "140px"
                : isHugeScreen
                    ? "160px"
                    : "180px";

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(!openModal);
    const handleClose = () => setOpenModal(!openModal);
    const handleJobOfferClick = () => {
        const handleAuthenticated = () => {
            console.log('DEBUG ->', props.url);
            window.open(props.url, '_blank');
        };
          
        const handleNotAuthenticated = () => {
            localStorage.setItem('desiredJob', `${JSON.stringify(props)}`); // Save something to localStorage
            navigate(`/register/${props.id}`);
        };
          
        isAuthenticated ? handleAuthenticated() : handleNotAuthenticated();
    };
    let category = '';
    let salary = '';
    if (props.category === 'Unknown') category = undefined;
    else category = props.category
    if (props.salaryMin && props.salaryMax) {
        if (props.salaryMin === props.salaryMax) salary = `${props.salaryMin}`
        else salary = `${props.salaryMin} - ${props.salaryMax}`;
    }
    if (props.salaryMin && !props.salaryMax) salary = `${props.salaryMin}`;
    if (!props.salaryMin && props.salaryMax) salary = `${props.salaryMax}`;
    if (!props.salaryMin && !props.salaryMax) salary = undefined;
    return (
        <Card
            sx={{
                maxWidth: cardSize,
                minWidth: cardSize,
                maxHeight: cardHeight,
                minHeight: cardHeight,
                backgroundColor: cardColors[cardColorPicker],
                borderRadius: "16px",
                margin: "5px",
            }}
        >
            <CardActionArea
                sx={{
                    height: cardHeight,
                    "&:hover .MuiCardActionArea-focusHighlight": {
                        opacity: 0.3,
                    },
                }}
                onClick={handleOpen}
                className="jobpost_card"
            >
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box className={darkMode ? 'modal_box box_dark' : 'modal_box box_light'}>
                        <h2 id="parent-modal-title" className="job_title">{props.title}</h2>
                        <p id="parent-modal-description">{props.description}</p>
                        <Button 
                            onClick={handleJobOfferClick}
                            sx={{ padding: '10px 20px', fontSize: '1rem', color: 'rgb(1, 173, 1)', minWidth: '130px' }}
                            endIcon={<ArrowForwardIcon />}>{traduction("jobPost.apply")}</Button>
                        <Divider />
                        <SocialShare jobId={props.id} jobTitle={props.title}/>
                    </Box>
                </Modal>
                <CardContent sx={{ flex: 1, maxHeight: cardHeight }}>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        height="60"
                    >
                        <Stack
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                variant="dot"
                            >
                                <Avatar
                                    alt="Remy Sharp"
                                    sx={{ width: 36, height: 36 }}
                                    src={
                                        props.image ? props.image : defaultJobImage
                                    }
                                />
                            </StyledBadge>
                        </Stack>
                        <Stack
                            direction='column'
                        >
                            <Typography
                                gutterBottom
                                variant="subtitle1"
                                component="div"
                                color={darkMode ? "white" : "black"}
                            >
                                {props.title}
                            </Typography>
                            <Typography variant="body2" color={darkMode ? "white" : "black"}>{props.company}</Typography>
                            <Typography variant="body2" color={darkMode ? "white" : "black"}>{category}</Typography>
                        </Stack>
                        <Stack
                            sx={{
                                position: "absolute",
                                bottom: 10,
                                right: 10,
                                opacity: 0.8,
                            }}>
                            {salary && <Chip sx={{ backgroundColor: 'yellow', color: 'black', fontWeight: 'bold' }} icon={<EuroIcon sx={{ color: 'blue !important' }} />} label={salary} />}
                        </Stack>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default JobPost;
