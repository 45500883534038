import Cookies from "js-cookie";

// Function to set the access token in an HTTP-only cookie
export const setAuthToken = (tokenData, type) => {
    // Set the secure flag to ensure the cookie is only transmitted over HTTPS
    const expirationDate = new Date(tokenData.expires)
    Cookies.set(type, tokenData.token, {
        secure: true,
        sameSite: "strict",
        expires: expirationDate,
    });
};

// Function to get the access token from the cookie
export const getAuthToken = (type) => {
    return Cookies.get(type);
};

// TODO -> implement log out functionality
// Function to remove the access token from the cookie
export const removeAuthToken = (type) => {
    Cookies.remove(type);
};
