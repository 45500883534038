import React, { useEffect, useState } from "react"
import { useSearchParams, Navigate } from "react-router-dom"
import serverConnect from "../../axios";

const Verify = () => {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const [isUserVerified, setUserVerified] = useState(false);
    const [invalidAction, setInvalidAction] = useState(false);
    const jobID = searchParams.get('jobID');

    useEffect(() => {
        const queryServer = async (userToken) => {
            try {
                const resp = await serverConnect.post('/auth/verify-email', { token: userToken});
                if (resp.status === 204) setUserVerified(!isUserVerified);
            }catch (err) {
                setInvalidAction(!invalidAction);
            }
        };
        const userToken = searchParams.get('token');
        queryServer(userToken);
    }, [jobID]);

    return(
        <div>
            {/**TODO: Display a clear "try again later" error if invalidAction! */}
            {isUserVerified ? (
                jobID ? <Navigate to={`/login/${jobID}`} replace={true} /> : <Navigate to="/login" replace={true} />
            ) : invalidAction ? (
                <Navigate to="/register" replace={true} />
            ) : <></>}
        </div>
    )
}

export default Verify;