import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Search from "@mui/icons-material/Search";
import Send from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import "./style.css";

const SearchBar = ({ searchCallBack }) => {
    // eslint-disable-next-line no-unused-vars
    const [ traduction, _ ] = useTranslation("global");
    const [inputValue, setInputValue] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const typingTimeoutRef = useRef(null);

    const suggestions = [
        traduction("suggestedJobs.1"),
        traduction("suggestedJobs.2"),
        traduction("suggestedJobs.3"),
        traduction("suggestedJobs.4"),
        traduction("suggestedJobs.5"),
        traduction("suggestedJobs.6"),
        traduction("suggestedJobs.7"),
        traduction("suggestedJobs.8"),
        traduction("suggestedJobs.9"),
        traduction("suggestedJobs.10"),
        traduction("suggestedJobs.11"),
        traduction("suggestedJobs.12"),
        traduction("suggestedJobs.13"),
        traduction("suggestedJobs.14"),
        traduction("suggestedJobs.15"),
        traduction("suggestedJobs.16"),
        traduction("suggestedJobs.17"),
        traduction("suggestedJobs.18"),
        traduction("suggestedJobs.19"),
        traduction("suggestedJobs.20"),
    ];

    const getRandomSuggestion = (exclude) => {
        let suggestion;
        do {
            suggestion = suggestions[Math.floor(Math.random() * suggestions.length)];
        } while (suggestion === exclude);
        return suggestion;
    };

    const typeText = (text, index = 0, reverse = false) => {
        if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    
        if (index >= 0 && index <= text.length) {
            setPlaceholder(text.slice(0, index));
    
            const nextStep = () => {
                const newIndex = reverse ? index - 1 : index + 1;
                typeText(text, newIndex, reverse);
            };
    
            const delay = 100;
            typingTimeoutRef.current = setTimeout(nextStep, delay);
        } else if (!reverse) {
            typingTimeoutRef.current = setTimeout(() => typeText(text, text.length, true), 1000);
        } else {
            const newSuggestion = getRandomSuggestion(text);
            setPlaceholder("");
            typingTimeoutRef.current = setTimeout(() => typeText(newSuggestion, 0, false), 500);
        }
    };

    const handleFocus = () => {
        if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
        setPlaceholder("");
    };

    const handleBlur = () => {
        typeText(placeholder, 0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        searchCallBack(inputValue);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    useEffect(() => {
        const initialSuggestion = getRandomSuggestion();
        typeText(initialSuggestion, 0);
        return () => {
            if (typingTimeoutRef.current)
                clearTimeout(typingTimeoutRef.current);
        };
    }, []);

    /* useEffect(() => {
        searchCallBack(inputValue);
    }, [inputValue]); */

    return (
        <TextField
            id="outlined-basic"
            variant="outlined"
            className="search_bar"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholder}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleSubmit}>
                            <Send />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
        />
    );
};

export default SearchBar;