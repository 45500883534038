import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { ThemeContext } from "../../theme";
import './style.css';

// online green dot styling
const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));


const UserAvatar = ({ online }) => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    return (
        <Stack direction="row" spacing={2}>
            <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant={online ? "dot" : ""}
            >
                <Avatar sx={darkMode ? { backgroundColor: ''} : {backgroundColor: '#4D7FB4'}} /> {/**A fake img path makes it default to the user icon */}
            </StyledBadge>
        </Stack>
    );
}

export default UserAvatar;
