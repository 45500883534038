import serverConnect from "../../axios";
import { getAuthToken, setAuthToken } from "../../utils/auth/authService";

const validateToken = async (tk) => {
    try {
        const headers = {
            Authorization: `Bearer ${tk}`,
        };
        const config = {
            headers: headers,
        };
        const isValidTk = await serverConnect.post(`/auth/session`, {}, config);
        return isValidTk.data.isValid;
    } catch (err) {
        if (err.response.status === 401) {
            return false;
        }
    }
    return false;
};

const getNewAccessToken = async (refreshToken) => {
    try {
        const refreshedTokens = await serverConnect.post(
            "/auth/refresh-tokens",
            { refreshToken }
        );

        const newRefreshToken = refreshedTokens.data.refresh.token;
        const newAccessToken = refreshedTokens.data.access.token;

        setAuthToken(refreshedTokens.data.refresh, "refresh_token");
        setAuthToken(refreshedTokens.data.access, "access_token");

        return { newRefreshToken, newAccessToken  }; 

    } catch (err) {
        return { newRefreshToken: false, newAccessToken: false };
    }
};

const useSession = async () => {
    let userAccessToken = getAuthToken("access_token");
    let userRefreshToken = false;
    if (!userAccessToken) {
        userRefreshToken = getAuthToken("refresh_token");
        if (!userRefreshToken) return false;
        const newTokens = await getNewAccessToken(userRefreshToken);
        if (!newTokens.newAccessToken) return false;
        if (!newTokens.newRefreshToken) return false;
        userAccessToken = newTokens.newAccessToken;
    }

    return userAccessToken ? await validateToken(userAccessToken) : false;
};

export default useSession;
