import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//--------------TRANSLATION ROOT CONFIG----------
import "./i18nextConf.js";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
//--------------THEME ROOT CONFIG----------
import { ThemeProvider } from "./theme.js";
import { createTheme } from "@mui/material";
//--------------AUTH ROOT CONFIG----------
import { AuthProvider } from "./contexts/AuthContext";


i18next.init({
    interpolation: { escapeValue: false },
    lng: "es",
    resources: {
        es: {
            global: global_es,
        },
        en: {
            global: global_en,
        },
    },
});

const theme = createTheme({
    typography: {
        fontFamily: "Ysabeau",
    }
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <I18nextProvider i18n={i18next}>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </ThemeProvider>
        </I18nextProvider>
    </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
