import React from "react";
import Home from "./pages/home";
import Profile from "./pages/profile";
import Header from "./components/header";
import PrivateRoute from "./components/private-route";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import Login from "./pages/login";
import Register from "./pages/register";
import Verify from "./pages/verify";
import SingleJob from "./pages/single-job";

function App() {
    const { isAuthenticated } = useAuth();

    console.log("(╯°□°）╯︵ ┻━┻ -> App rendered");
    console.log("%cThis website has been made with %c❤️ %cand 💪 by Diego Tellez", "color: white;", "color: red;", "color: white;");
    console.log("[👇]")
    console.log("[🚀] I truly believe remote roles bring joy and happiness to certain individuals. I believe society deserves better and therefore I bring remote roles oportunities to the people who want them.");
    /* console.log("You can get to know me better or contact me via my personal website:")
    console.log("[+] https://www.diego-tellez.com/") */

    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/jobs" element={<Home />} />
                <Route path="/login/:jobID" element={<Login />} />
                <Route path="/login/" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/register/:from" element={<Register />} />
                <Route path="/verify-email" element={<Verify />} />
                <Route path="/job/:jobID" element={<SingleJob />} />
                {/**--------------PRIVATE ROUTES -------------------------------*/}
                <Route
                    path="/profile"
                    element={
                        <PrivateRoute isSignedIn={isAuthenticated}>
                            <Profile />
                        </PrivateRoute>
                    }
                />
                {/**--------------END OF PRIVATE ROUTES -------------------------*/}
                <Route path="*" element={<Home />} />
            </Routes>
        </Router>
    );
}

export default App;
