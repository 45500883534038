import React, { useContext, useEffect, useState } from "react"
import JobPost from "../../components/job-post"
import './style.css';
import Button from "@mui/material/Button";
import { ThemeContext } from "../../theme";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import serverConnect from "../../axios";

const SingleJob = () => {
    const [traduction, setLanguage] = useTranslation("global"); // eslint-disable-line no-unused-vars
    const [job, setJob] = useState(undefined);
    const darkMode = useContext(ThemeContext).state.darkMode;
    const navigate = useNavigate();
    // extract jobID from path params
    const { jobID } = useParams();
    const CustomButton = styled(Button)({
        color: darkMode ? "black" : "white",
        backgroundColor: darkMode ? "white" : "black",
        "&:hover": {
            backgroundColor: darkMode ? "gray" : "gray",
        },
    });
    
    const handleRedirect = () => {
        navigate("/home");
    };

    const findJob = async (jobID) => {
        try {
            const resp = await serverConnect.get(`/jobs/${jobID}`);
            setJob(resp.data);
            
        }catch (err) {
            // TODO -> display error and redirect home
            // console.log(err)
        }
    };

    useEffect(() => {
        findJob(jobID);
    }, []);
    // TODO: add route for this page to open with an id as path param. In such case, we look for job with findOneById in DB.
    return(
        <div className="job-main-layout">
            {job && <JobPost
                id={job.id}
                key={"unique"}
                title={job.title}
                company={job.company}
                category={job.category}
                location={job.location}
                salaryMin={job.salary_min}
                salaryMax={job.salary_max}
                description={job.description}
                age={job.created}
                url={job.redirect_url}
            />}
            <br/>
            <CustomButton
                key="register-btn"
                margin="dense"
                variant="outlined"
                onClick={handleRedirect}
            >
                {traduction("job.redirect")}
            </CustomButton>
        </div>
    )
}

export default SingleJob;