import React from "react";
import {
    WhatsappShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    TelegramShareButton,
    TwitterShareButton,
} from "react-share";
import {
    WhatsappIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    TelegramIcon,
    XIcon,
} from "react-share";
import { useTranslation } from "react-i18next";
import './style.css';

const SocialShare = ({ jobId, jobTitle}) => {
// eslint-disable-next-line no-unused-vars
    const [traduction, _] = useTranslation("global");
    const shareUrl = `https://teletrabajo.app/job/${jobId}`;
    const title = `${traduction("share.title")} ${jobTitle}`

    return (
        <div className="share_main">
            <WhatsappShareButton url={shareUrl} title={title}>
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <FacebookMessengerShareButton url={shareUrl}>
                <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>

            <TelegramShareButton url={shareUrl} title={title}>
                <TelegramIcon size={32} round />
            </TelegramShareButton>

            <TwitterShareButton url={shareUrl} title={title}>
                <div
                    style={{
                        padding: "1px",
                        display: "inline-block",
                        borderRadius: "50%",
                        background: "white",
                        lineHeight: 0,
                    }}
                >
                    <XIcon size={32} round />{" "}
                </div>
            </TwitterShareButton>

            <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round />
            </FacebookShareButton>
        </div>
    );
};

export default SocialShare;
